import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 858.000000 890.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,890.000000) scale(0.100000,-0.100000)">



<path d="M4241 7496 c10 -9 79 -14 79 -5 0 5 -19 9 -42 9 -23 0 -39 -2 -37 -4z"/>
<path d="M5273 7453 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3773 7373 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5678 7333 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3553 7293 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5803 7293 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3475 7260 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5880 7260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3388 7213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3055 7030 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2945 6950 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2978 6903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2870 6888 c0 -20 14 -38 30 -38 23 0 32 13 24 33 -5 14 -54 19 -54
5z"/>
<path d="M2798 6853 c-15 -16 -45 -47 -68 -69 -28 -29 -37 -43 -27 -47 28 -10
155 96 157 131 0 21 -34 13 -62 -15z"/>
<path d="M6665 6750 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6740 6670 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6820 6596 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M7230 6390 c0 -11 7 -20 15 -20 17 0 18 2 9 24 -9 23 -24 20 -24 -4z"/>
<path d="M7005 6370 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M6383 6292 c-3 -7 -2 -17 3 -21 5 -5 77 -3 159 4 167 13 151 22 -49
28 -84 2 -109 0 -113 -11z"/>
<path d="M6840 6251 c0 -10 48 -21 91 -21 19 0 29 5 29 15 0 12 -13 15 -60 15
-33 0 -60 -4 -60 -9z"/>
<path d="M7115 6250 c3 -5 -8 -16 -26 -25 -36 -19 -36 -20 14 -28 33 -5 39 -3
46 16 11 29 3 47 -21 47 -10 0 -17 -4 -13 -10z"/>
<path d="M3995 5129 c-4 -6 -5 -12 -2 -15 7 -7 37 7 37 17 0 13 -27 11 -35 -2z"/>
<path d="M4125 5130 c-3 -6 1 -13 9 -16 22 -8 68 4 61 16 -8 13 -62 13 -70 0z"/>
<path d="M4815 5130 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z"/>
<path d="M5078 4973 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3985 4908 c-2 -13 -1 -39 4 -58 8 -33 9 -31 10 23 1 59 -5 74 -14
35z"/>
<path d="M6765 4890 c11 -5 40 -9 65 -9 25 0 54 4 65 9 13 6 -9 9 -65 9 -56 0
-78 -3 -65 -9z"/>
<path d="M7208 4808 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M4100 4540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7547 4516 c-6 -15 1 -26 15 -26 11 0 10 27 -1 34 -5 3 -11 0 -14 -8z"/>
<path d="M4150 4475 c0 -8 8 -18 18 -22 9 -3 25 -14 35 -25 22 -22 40 -24 35
-3 -2 9 -22 27 -45 41 -35 21 -43 22 -43 9z"/>
<path d="M7585 4441 c-5 -10 -14 -81 -15 -128 0 -14 -6 -23 -15 -23 -9 0 -18
-7 -21 -15 -4 -8 -12 -15 -19 -15 -7 0 -16 -11 -19 -25 -8 -32 -26 -33 -26 -1
0 13 -3 31 -6 39 -3 8 2 21 10 30 20 19 21 37 3 37 -8 0 -22 -16 -33 -35 -10
-19 -24 -37 -31 -39 -19 -8 -153 6 -153 15 0 5 -13 9 -30 9 -16 0 -30 -4 -30
-10 0 -6 -33 -10 -80 -10 -65 0 -80 3 -80 15 0 13 -22 15 -157 13 l-158 -3 -3
-33 c-3 -32 -5 -33 -35 -25 -52 13 -172 10 -170 -4 2 -8 -8 -13 -25 -14 -33
-1 -85 -9 -90 -14 -2 -2 -1 -8 3 -14 4 -7 46 -11 105 -11 106 0 130 -5 130
-26 0 -7 18 -18 40 -24 30 -8 42 -17 47 -38 10 -34 10 -235 0 -283 -8 -38 -29
-50 -122 -64 -21 -4 -52 -13 -67 -21 -22 -12 -140 -14 -704 -14 -608 0 -679 2
-695 16 -17 15 -20 14 -42 -10 -29 -31 -37 -65 -37 -150 0 -44 -7 -74 -24
-107 -12 -26 -26 -55 -30 -65 -3 -11 -18 -37 -31 -59 -14 -22 -30 -52 -36 -67
-6 -14 -17 -29 -25 -32 -8 -3 -14 -15 -14 -27 0 -28 -46 -71 -84 -78 -16 -4
-38 -19 -49 -33 -29 -39 -12 -74 40 -85 21 -4 499 -8 1063 -8 786 0 1030 3
1046 12 12 7 59 15 105 19 53 5 95 14 114 26 16 10 40 18 53 18 27 0 113 42
131 64 8 9 22 16 32 16 27 0 106 77 130 128 12 24 32 50 44 58 23 15 55 79 55
109 0 9 9 34 19 58 15 31 21 70 23 150 3 77 7 110 17 116 11 7 13 50 12 201
-2 191 -11 255 -33 217 -5 -10 -16 -17 -23 -17 -21 0 -21 96 0 116 19 19 29
75 21 119 -7 34 -19 44 -31 26z"/>
<path d="M4247 4401 c-9 -10 -7 -16 6 -26 18 -13 66 -7 67 8 0 4 0 13 0 20 0
16 -59 16 -73 -2z"/>
<path d="M6563 4403 c-21 -7 -15 -33 7 -33 11 0 20 6 20 14 0 18 -11 25 -27
19z"/>
<path d="M7473 4375 c-3 -9 -3 -18 0 -21 9 -9 27 6 27 22 0 19 -19 18 -27 -1z"/>
<path d="M6415 4358 c-19 -12 -19 -12 5 -19 14 -4 34 -7 44 -8 18 -1 18 0 1
19 -21 23 -23 23 -50 8z"/>
<path d="M4346 4352 c-3 -6 8 -12 24 -14 19 -3 29 -1 27 6 -5 14 -43 20 -51 8z"/>
<path d="M4478 4327 c-53 -15 -47 -29 9 -25 36 2 49 7 51 21 4 19 -5 20 -60 4z"/>
<path d="M6130 4336 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M5055 4311 c-33 -14 -403 -23 -426 -10 -18 9 -20 8 -17 -13 3 -23 5
-23 158 -29 224 -8 605 21 597 45 -4 13 -282 19 -312 7z"/>
<path d="M5817 4310 c-9 -6 -15 -15 -12 -21 7 -11 -165 -11 -245 0 -49 7 -57
6 -79 -15 -18 -17 -32 -21 -53 -17 -20 4 -28 2 -28 -9 0 -8 6 -15 13 -16 6 -1
37 -6 67 -13 36 -7 160 -10 363 -7 257 2 309 5 317 18 6 10 32 15 82 18 40 2
77 7 81 12 5 4 -12 6 -37 5 -26 -2 -44 1 -40 6 3 5 37 9 75 9 51 0 68 -3 63
-12 -4 -7 -3 -8 4 -4 7 4 12 2 12 -3 0 -18 75 -12 101 7 13 10 33 23 44 27 17
8 14 10 -18 13 -21 2 -45 -2 -52 -8 -18 -15 -223 -14 -241 1 -6 5 -34 9 -60 8
-27 -1 -114 1 -194 5 -98 4 -151 3 -163 -4z m280 -27 c36 -14 3 -23 -82 -23
-53 0 -95 4 -95 9 0 6 -19 12 -42 14 -24 2 13 4 82 4 68 1 130 -2 137 -4z"/>
<path d="M883 4180 c0 -47 2 -66 4 -42 2 23 2 61 0 85 -2 23 -4 4 -4 -43z"/>
<path d="M6188 4203 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3398 4153 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z"/>
<path d="M3680 4146 c5 -5 42 -4 87 1 l78 10 -87 -1 c-49 0 -83 -5 -78 -10z"/>
<path d="M3967 4147 c12 -5 24 -17 26 -26 3 -9 5 -4 6 10 1 23 -3 26 -26 26
-27 0 -27 0 -6 -10z"/>
<path d="M3988 4075 c2 -14 6 -25 8 -25 2 0 4 11 4 25 0 14 -4 25 -9 25 -4 0
-6 -11 -3 -25z"/>
<path d="M3992 3940 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M921 3738 c-1 -27 4 -48 9 -48 12 0 12 47 0 75 -6 15 -9 8 -9 -27z"/>
<path d="M3820 3772 c0 -5 7 -9 15 -9 8 0 15 4 15 9 0 4 -7 8 -15 8 -8 0 -15
-4 -15 -8z"/>
<path d="M2235 3730 c-6 -10 314 -15 730 -13 88 0 285 1 438 2 177 1 277 5
277 11 0 14 -1436 14 -1445 0z"/>
<path d="M961 3544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4802 3510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1310 3240 c6 -11 17 -20 26 -20 12 0 12 4 4 20 -6 11 -17 20 -26 20
-12 0 -12 -4 -4 -20z"/>
<path d="M1190 3231 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4510 3205 c0 -15 26 -32 36 -23 2 3 2 13 -2 22 -8 20 -34 21 -34 1z"/>
<path d="M1414 3195 c-3 -8 -3 -19 1 -25 11 -18 25 -11 25 15 0 27 -17 34 -26
10z"/>
<path d="M1265 3191 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4338 3183 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M1336 3150 c-18 -6 -18 -7 -3 -13 9 -3 27 -3 39 2 20 7 20 8 2 13
-11 3 -28 2 -38 -2z"/>
<path d="M4180 3150 c-12 -8 -10 -10 10 -11 78 -5 97 -4 93 9 -6 14 -81 16
-103 2z"/>
<path d="M4420 3150 c0 -5 14 -10 31 -10 17 0 28 4 24 10 -3 6 -17 10 -31 10
-13 0 -24 -4 -24 -10z"/>
<path d="M1480 3133 c-71 -7 -83 -12 -69 -29 9 -11 28 -15 58 -13 64 2 71 1
71 -15 0 -8 15 -17 36 -21 42 -7 184 4 184 15 0 8 -73 20 -119 20 -44 0 -101
20 -101 35 0 8 -6 14 -12 13 -7 0 -29 -3 -48 -5z"/>
<path d="M2164 3128 c-3 -4 -23 -8 -45 -8 -33 0 -39 -3 -39 -21 0 -21 3 -22
61 -15 34 3 70 6 80 6 11 0 19 7 19 16 0 14 9 16 50 12 28 -3 50 -1 50 3 0 5
-21 9 -47 10 -27 1 -65 2 -86 3 -21 1 -40 -1 -43 -6z"/>
<path d="M3637 3123 c4 -11 8 -13 11 -5 2 7 8 10 13 6 5 -3 9 0 9 5 0 6 -9 11
-20 11 -14 0 -18 -5 -13 -17z"/>
<path d="M1760 3110 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10
-22 0 -40 -4 -40 -10z"/>
<path d="M3792 3111 c-6 -3 22 -7 61 -8 51 -2 65 1 50 7 -24 10 -95 11 -111 1z"/>
<path d="M4300 3105 c7 -9 23 -15 34 -13 33 5 24 23 -13 26 -31 3 -33 2 -21
-13z"/>
<path d="M4051 3101 c-21 -14 -6 -38 29 -46 18 -4 52 -4 77 0 37 5 42 9 32 21
-7 9 -30 14 -60 14 -27 0 -49 5 -49 10 0 12 -10 13 -29 1z"/>
<path d="M2383 3086 c39 -30 19 -34 -140 -29 -118 4 -160 9 -166 19 -11 20
-77 18 -77 -1 0 -11 -11 -15 -37 -15 -53 0 -164 -21 -158 -30 10 -16 126 -20
469 -13 350 6 359 7 336 25 -12 10 -28 18 -36 18 -8 0 -14 6 -14 14 0 13 -80
26 -159 26 -32 -1 -34 -2 -18 -14z"/>
<path d="M3639 3086 c-4 -18 -16 -20 -181 -24 -124 -2 -178 0 -178 7 0 8 -85
11 -280 11 -180 0 -280 -4 -280 -10 0 -5 -18 -10 -40 -10 -27 0 -40 -4 -40
-14 0 -21 107 -27 462 -26 178 1 434 -1 570 -3 210 -5 247 -3 252 9 8 20 -1
21 -131 28 -125 7 -140 10 -146 34 -3 14 -4 14 -8 -2z"/>
<path d="M1387 2924 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21 -31
12z"/>
<path d="M1470 2910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7077 2894 c-1 -2 -28 -6 -58 -9 -29 -4 -79 -11 -109 -16 -30 -5
-126 -12 -215 -15 -100 -3 -181 -11 -217 -21 -50 -14 -59 -20 -68 -49 -6 -18
-28 -51 -48 -72 -82 -88 -114 -126 -137 -167 -14 -23 -36 -47 -49 -55 -14 -7
-29 -25 -34 -39 -5 -15 -33 -45 -61 -66 -28 -21 -53 -46 -56 -55 -3 -9 -18
-23 -34 -33 -16 -9 -41 -33 -57 -53 -16 -20 -41 -42 -56 -50 -15 -8 -35 -28
-43 -44 -9 -17 -22 -30 -31 -30 -8 0 -17 -7 -20 -15 -3 -8 -16 -17 -27 -21
-12 -4 -46 -27 -76 -51 -30 -24 -68 -51 -85 -60 -17 -9 -35 -22 -39 -29 -4 -8
-18 -14 -31 -14 -13 0 -31 -9 -41 -20 -10 -11 -24 -20 -31 -20 -7 0 -35 -18
-63 -40 -28 -22 -55 -40 -61 -40 -10 0 -92 -45 -148 -82 -34 -23 -70 -38 -86
-38 -8 0 -19 -6 -25 -14 -6 -7 -31 -16 -56 -18 -24 -3 -51 -13 -60 -22 -9 -9
-32 -16 -50 -16 -24 0 -38 -6 -45 -20 -8 -15 -21 -20 -53 -20 -26 0 -51 -8
-67 -20 -14 -11 -40 -20 -58 -20 -18 0 -50 -9 -72 -20 -28 -14 -59 -20 -110
-20 -51 0 -82 -6 -110 -20 -33 -17 -59 -20 -163 -20 -86 0 -137 -5 -170 -16
-37 -13 -86 -15 -266 -12 -159 2 -223 6 -233 16 -8 8 -47 12 -123 12 -86 0
-116 4 -134 16 -14 10 -52 19 -95 22 -48 3 -79 11 -95 23 -15 12 -40 19 -66
19 -27 0 -51 7 -68 20 -15 12 -40 20 -61 20 -20 0 -49 9 -64 19 -15 11 -51 22
-80 25 -33 4 -74 20 -111 41 -33 19 -65 35 -73 35 -7 0 -22 7 -32 15 -11 8
-32 15 -48 15 -17 0 -33 8 -40 20 -8 12 -24 20 -40 20 -17 0 -31 7 -38 19 -5
11 -20 23 -31 26 -33 11 -210 115 -229 135 -3 3 -39 28 -79 55 -86 57 -97 66
-147 118 -21 20 -43 37 -50 37 -7 0 -14 3 -16 8 -1 4 -25 25 -53 47 -99 79
-318 310 -320 338 -1 9 -11 28 -23 40 -12 13 -22 29 -22 36 0 8 -10 24 -23 36
-12 12 -41 42 -64 68 -23 26 -47 47 -53 47 -6 0 -16 14 -22 30 -5 17 -15 29
-22 27 -20 -7 -18 -38 3 -58 12 -11 21 -31 21 -45 0 -13 7 -27 15 -30 8 -4 15
-13 15 -20 0 -8 9 -25 20 -37 42 -47 76 -94 95 -129 11 -21 22 -38 26 -38 3 0
19 -19 35 -42 16 -24 54 -69 86 -101 32 -33 58 -63 58 -68 0 -5 6 -9 13 -9 7
0 19 -16 26 -36 17 -47 48 -83 88 -99 17 -7 60 -40 95 -74 63 -59 88 -82 139
-125 14 -12 28 -29 32 -39 4 -10 15 -20 24 -24 10 -4 41 -22 68 -40 28 -19 58
-39 69 -46 10 -7 39 -28 65 -48 25 -20 58 -42 72 -50 31 -17 116 -73 144 -94
10 -8 26 -15 34 -15 8 0 26 -11 40 -24 32 -30 156 -96 181 -96 9 0 23 -6 29
-14 7 -8 27 -18 44 -21 18 -4 39 -13 47 -20 23 -19 100 -53 147 -65 23 -6 45
-15 48 -20 3 -6 17 -10 31 -10 14 0 37 -6 53 -14 15 -8 61 -22 102 -31 41 -9
84 -21 97 -26 13 -5 38 -9 56 -9 19 0 53 -9 77 -20 29 -13 66 -20 107 -20 48
0 70 -5 89 -20 22 -17 41 -20 122 -20 61 0 112 -6 141 -16 36 -13 92 -16 290
-16 135 1 270 5 300 11 30 6 101 15 157 20 56 6 112 15 125 22 13 7 59 15 103
19 45 4 96 14 114 23 19 9 50 17 70 17 20 0 47 7 59 16 12 8 58 19 102 23 44
5 91 16 104 25 14 9 34 16 45 16 11 0 23 4 26 10 3 5 25 14 48 21 23 6 50 18
60 27 10 9 28 17 39 19 12 2 29 10 38 18 9 8 23 15 32 15 8 0 27 9 41 20 14
11 36 20 50 20 13 0 36 9 50 20 14 11 35 20 47 20 25 0 80 27 80 39 0 8 22 21
110 66 11 5 20 13 20 17 0 5 8 8 19 8 10 0 24 7 31 15 7 8 16 15 21 15 10 0
50 29 184 133 41 31 79 57 85 57 5 0 75 64 155 143 80 78 180 176 222 217 43
41 80 80 83 86 6 14 79 100 143 168 26 28 62 75 80 104 38 62 84 141 93 161 4
7 17 24 30 38 12 13 26 38 29 54 4 16 11 31 16 34 16 10 10 43 -8 49 -20 6
-100 6 -106 0z"/>
<path d="M1635 2870 c28 -12 85 -12 85 0 0 6 -24 10 -52 9 -39 0 -48 -3 -33
-9z"/>
<path d="M1431 2834 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1967 2843 c-39 -10 -1 -23 68 -23 78 0 108 6 99 21 -5 9 -134 11
-167 2z"/>
<path d="M1460 2806 c0 -15 18 -31 26 -23 7 7 -7 37 -17 37 -5 0 -9 -6 -9 -14z"/>
<path d="M1511 2754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1705 2510 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1730 2485 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M5948 2413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2550 2370 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M2705 2330 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5736 2325 c-3 -8 -17 -20 -31 -25 -15 -6 -25 -17 -25 -30 0 -25 12
-25 35 0 10 11 23 20 29 20 6 0 16 11 23 25 10 22 9 25 -7 25 -10 0 -20 -7
-24 -15z"/>
<path d="M2685 2250 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M2825 2250 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M2039 2216 c16 -19 44 -21 39 -3 -3 6 -15 14 -28 15 -21 3 -22 2 -11
-12z"/>
<path d="M2802 2178 c5 -15 48 -18 48 -3 0 6 -11 11 -26 13 -18 3 -25 0 -22
-10z"/>
<path d="M2955 2170 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M2700 2151 c0 -12 29 -35 36 -28 11 10 -5 37 -21 37 -8 0 -15 -4 -15
-9z"/>
<path d="M2360 2135 c0 -17 2 -18 24 -9 18 7 12 24 -10 24 -8 0 -14 -7 -14
-15z"/>
<path d="M2505 2140 c-8 -12 20 -24 35 -15 6 3 8 11 5 16 -8 12 -32 12 -40 -1z"/>
<path d="M3030 2140 c-12 -8 -10 -10 8 -10 13 0 20 4 17 10 -7 12 -6 12 -25 0z"/>
<path d="M5535 2140 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M5425 2130 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2923 2103 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3120 2095 c0 -16 16 -20 24 -6 3 5 -1 11 -9 15 -9 3 -15 0 -15 -9z"/>
<path d="M5338 2103 c-24 -6 -23 -23 2 -23 11 0 20 7 20 15 0 8 -1 14 -2 14
-2 -1 -11 -3 -20 -6z"/>
<path d="M5417 2073 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M2270 2060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3200 2060 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M5224 2059 c-3 -6 -1 -16 4 -21 13 -13 45 8 36 23 -7 12 -31 11 -40
-2z"/>
<path d="M2593 2045 c-3 -9 -19 -23 -35 -31 -16 -8 -26 -19 -23 -24 11 -17 35
-11 43 11 5 11 12 21 17 21 15 1 45 22 45 30 0 15 -41 8 -47 -7z"/>
<path d="M3143 2032 c-27 -4 -31 -22 -5 -22 21 0 47 16 36 22 -5 2 -19 2 -31
0z"/>
<path d="M5329 2024 c-11 -14 -10 -16 9 -12 13 1 23 7 23 13 1 17 -17 16 -32
-1z"/>
<path d="M2928 2023 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3267 2000 c-26 -28 -27 -30 -8 -30 12 0 21 7 21 15 0 13 3 13 21 3
17 -11 22 -10 34 6 33 44 -26 49 -68 6z"/>
<path d="M5114 2019 c-4 -7 1 -18 11 -26 29 -20 47 -16 43 10 -3 25 -41 36
-54 16z"/>
<path d="M2389 1998 c10 -28 36 -30 36 -3 0 13 -8 21 -22 23 -20 3 -22 0 -14
-20z"/>
<path d="M2680 2005 c-7 -8 -20 -15 -28 -15 -13 0 -12 -3 4 -15 26 -20 60 -19
68 3 3 9 9 23 12 30 8 18 -41 16 -56 -3z"/>
<path d="M5260 1985 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M2960 1974 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"/>
<path d="M3411 1981 c-11 -7 -9 -10 8 -16 24 -7 44 2 36 16 -8 11 -26 11 -44
0z"/>
<path d="M5000 1980 c0 -17 49 -35 61 -23 6 6 8 16 5 22 -9 14 -66 14 -66 1z"/>
<path d="M2800 1965 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M5186 1954 c-6 -17 3 -24 31 -24 13 0 23 6 23 14 0 18 -47 27 -54 10z"/>
<path d="M2607 1954 c-3 -3 -3 -12 0 -19 3 -10 -7 -15 -34 -17 -42 -3 -48 -21
-8 -26 18 -2 34 5 51 23 13 14 24 30 24 36 0 10 -23 12 -33 3z"/>
<path d="M4883 1943 c-8 -19 -3 -21 40 -15 47 5 48 19 1 24 -24 3 -38 0 -41
-9z"/>
<path d="M2468 1943 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3530 1940 c-11 -7 -4 -10 28 -10 23 0 42 5 42 10 0 13 -50 13 -70 0z"/>
<path d="M5104 1916 c-18 -14 -18 -15 10 -26 36 -13 66 -4 66 21 0 23 -46 27
-76 5z"/>
<path d="M3096 1911 c-6 -9 35 -24 43 -15 6 5 -18 24 -31 24 -4 0 -9 -4 -12
-9z"/>
<path d="M3720 1913 c-67 -11 -65 -20 6 -28 55 -6 90 8 64 25 -15 10 -26 10
-70 3z"/>
<path d="M4658 1913 c-15 -4 -26 -23 -14 -23 3 0 37 -7 77 -15 67 -14 72 -14
84 2 22 30 0 43 -69 42 -33 -1 -69 -3 -78 -6z"/>
<path d="M2988 1892 c-34 -21 -38 -47 -6 -36 12 3 37 13 57 22 32 13 34 16 16
23 -28 11 -36 10 -67 -9z"/>
<path d="M2770 1882 c0 -12 -13 -23 -35 -32 -41 -16 -44 -27 -10 -36 27 -7 55
10 55 32 0 8 9 14 20 14 13 0 20 7 20 20 0 15 -7 20 -25 20 -17 0 -25 -5 -25
-18z"/>
<path d="M2610 1876 c0 -16 25 -24 52 -18 27 6 9 32 -23 32 -18 0 -29 -5 -29
-14z"/>
<path d="M3521 1872 c-6 -2 -7 -10 -4 -18 3 -9 19 -14 44 -14 30 0 39 -4 39
-17 0 -14 3 -14 12 -5 10 10 10 15 -2 22 -13 8 -13 11 -1 19 9 6 -1 10 -33 12
-25 2 -50 3 -55 1z"/>
<path d="M3948 1873 c-31 -5 -36 -18 -12 -27 9 -4 150 -6 313 -5 287 2 297 1
331 -19 19 -12 41 -22 48 -22 6 0 12 -6 12 -12 0 -10 2 -10 9 0 4 8 11 14 14
14 17 0 47 11 47 18 0 4 -23 10 -51 13 -32 4 -56 13 -64 24 -12 17 -38 18
-317 19 -167 1 -315 -1 -330 -3z"/>
<path d="M4868 1875 c-4 -4 19 -20 49 -36 41 -22 59 -27 67 -19 8 8 5 15 -9
26 -11 8 -18 18 -15 22 6 9 -83 15 -92 7z"/>
<path d="M5020 1870 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"/>
<path d="M2887 1834 c-11 -12 3 -24 27 -24 13 0 29 7 36 15 11 13 7 15 -22 15
-19 0 -38 -3 -41 -6z"/>
<path d="M3330 1825 c-8 -10 -31 -15 -71 -15 -46 0 -59 -3 -59 -15 0 -12 14
-15 65 -15 37 0 65 4 65 10 0 6 25 10 55 10 48 0 55 2 55 20 0 17 -7 20 -49
20 -31 0 -53 -5 -61 -15z"/>
<path d="M4802 1808 c3 -31 1 -33 -27 -34 -63 -1 -97 -26 -55 -39 28 -9 80 4
80 20 0 14 22 25 51 25 24 0 24 11 -1 38 -31 33 -52 29 -48 -10z"/>
<path d="M3145 1810 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2 0
-12 -4 -23 -9z"/>
<path d="M4355 1799 c-16 -5 -100 -8 -185 -6 -85 2 -168 0 -185 -4 -23 -5 -15
-7 33 -8 36 -1 62 -5 62 -12 0 -6 5 -7 13 -2 16 10 226 13 232 3 3 -4 18 -6
35 -5 17 1 33 -1 36 -6 9 -15 81 -10 98 6 8 8 26 15 40 15 14 0 26 3 26 8 0 4
-21 7 -47 8 -27 0 -66 3 -88 7 -22 3 -53 1 -70 -4z"/>
<path d="M2805 1790 c-4 -6 10 -10 35 -10 25 0 39 4 35 10 -3 6 -19 10 -35 10
-16 0 -32 -4 -35 -10z"/>
<path d="M2936 1776 c-28 -22 -29 -24 -10 -34 10 -5 32 -8 47 -5 23 5 29 1 34
-15 6 -25 55 -38 109 -28 26 5 34 11 30 22 -3 9 -6 18 -6 20 0 2 -21 4 -48 4
-36 0 -50 5 -59 20 -12 18 -10 19 40 15 28 -2 41 -2 27 1 -14 3 -29 10 -34 15
-20 19 -100 10 -130 -15z"/>
<path d="M3520 1790 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10
-22 0 -40 -4 -40 -10z"/>
<path d="M3620 1745 c-14 -8 -42 -14 -62 -15 -21 0 -38 -4 -38 -10 0 -5 -19
-11 -42 -11 l-43 -2 45 -8 c104 -19 112 -21 99 -30 -8 -5 -48 -9 -90 -9 -54 0
-80 -4 -88 -14 -19 -23 0 -27 136 -28 102 -1 128 1 131 13 3 12 2 12 -6 0 -7
-10 -11 0 -14 34 -3 40 0 49 14 53 24 6 24 42 1 42 -10 -1 -29 -7 -43 -15z"/>
<path d="M3840 1730 l0 -30 -80 0 c-79 0 -80 0 -80 -25 0 -25 1 -25 80 -25 53
0 80 4 80 11 0 17 32 38 58 39 18 0 22 6 22 30 0 28 -2 30 -40 30 -38 0 -40
-2 -40 -30z"/>
<path d="M4547 1725 c-16 -22 -25 -25 -82 -25 -35 0 -65 4 -67 9 -1 5 -61 11
-132 14 -110 5 -134 3 -158 -11 -31 -18 -39 -38 -10 -27 9 4 81 8 160 10 131
3 142 2 142 -15 0 -13 11 -19 43 -24 53 -8 102 7 93 30 -5 13 3 15 47 14 83
-3 97 -1 97 13 0 12 -72 37 -103 37 -6 0 -20 -12 -30 -25z"/>
<path d="M3319 1731 c-26 -4 -54 -12 -62 -19 -8 -7 -28 -15 -44 -16 -15 -2
-32 -4 -37 -5 -5 0 -3 -8 4 -16 16 -20 106 -20 144 0 15 8 32 13 36 10 5 -3
18 -2 29 3 20 7 20 9 5 30 -8 12 -18 21 -22 21 -4 -1 -28 -5 -53 -8z"/>
<path d="M3940 1686 c0 -19 27 -46 46 -46 17 0 19 31 2 48 -17 17 -48 15 -48
-2z"/>
<path d="M4000 1690 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4108 1652 c-25 -2 -52 -10 -58 -18 -11 -12 7 -14 134 -14 123 0 146
2 146 15 0 8 -6 16 -12 17 -21 3 -160 3 -210 0z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
